/*

Polo - Multipurpose HTML5 Template
Version: 5.9.9
Website: https://inspirothemes.com/themes/polo
License: https://inspirothemes.com/themes/polo/license.html
Inspiro Themes (https://inspirothemes.com)
Author: INSPIRO - Ardian Berisha
Copyright 2021

*/

//Google Fonts
@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,400,500,600,700,800|Nunito:300,400,600,700,800');

// Bootstrap Configuration
@import "../../node_modules/bootstrap/scss/functions";

// Polo variables
@import "variables";

// Bootstrap Configuration
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
//@import "../../node_modules/bootstrap/scss/utilities";

// Mixins
@import "mixins";

// Bootstrap optional
//@import "bootstrap";

// Polo
//@import "core";
//@import "layout";

// utilities
@import "utilities";

// widgets
@import "widgets";

// Base
@import "base/include";

// Vendor
@import "vendor";

// Vendor
@import "components";

// Elements
@import "elements/include";

.download-button {
  &:hover {
    opacity: 0.9 !important;
  }
}

.welcome-section {
  &__text {
    text-align: left;
  }

  &__img {
    float: right;
    margin-left: 20px;

    @media (max-width: 991px) {
      max-width: 240px;
    }

    @media (max-width: 600px) {
      float: none;
      margin: 20px auto;
      display: block;
    }
  }
}

@media (max-width: 500px) {
  .rev_slider, .tp-simpleresponsive {
    .download-button {
       img {
        max-width: 40vw !important;
         height: auto !important;
      }
    }
  }
}
